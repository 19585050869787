import React from 'react'; //eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'; //eslint-disable-line no-unused-vars
import deepEqual from 'deep-eql';
import { rgba } from '../../styles/colors';
import Styled from 'styled-components';
import { colors } from '../../styles/colors';
import VCenter from 'xureact/lib/module/components/layout/vcenter';
import { FlexLayout, FlexChild } from 'xureact/lib/module/components/layout/flex-layout';

class UseCase extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        with: PropTypes.node,
    };
    
    render() {
        const props = this.props;
        return (
            <div className={props.className}>
                <FlexLayout wrap="wrap">
                    <FlexChild width={100} grow={1} shrink={0} style={{minHeight: 128, maxWidth: 160}}>
                        <VCenter>
                            <div className="use-case-picto">
                                <i className={props.pic}/>
                            </div>
                        </VCenter>
                    </FlexChild>
                    <FlexChild width={600} grow={1} shrink={1}>
                        <h3>{props.title} <span className="use-case-with">with <span className="use-case-with-action">{props.with}</span></span></h3>
                        <p>
                            <i>"{props.children}"</i>
                        </p>
                    </FlexChild>
                </FlexLayout>
            </div>
        );
    }
    
    shouldComponentUpdate(nextProps) {
        return !deepEqual(this.props, nextProps);
    }
}

//language=SCSS
UseCase = Styled(UseCase)`
& {
  background: ${rgba('#555', 0.9)};
  padding: 20px;
  > p {
    margin: 0;
  }
  margin-bottom: 35px;
  
  .use-case-with {
    text-transform: none;
  }
  
  .use-case-with-action {
    color: ${colors.primary};
  }
  
  .use-case-picto {
    position: relative;
    top: 2px;
    display: inline-block;
    background: rgba(0,0,0, 0.3);
    border-radius: 100px;
    width: 100px;
    height: 100px;
    font-size: 50px;
    line-height: 100px;
    color: ${colors.primaryDark};
  }
}
`;

export default UseCase;
