import React from 'react'; //eslint-disable-line no-unused-vars
import Styled from 'styled-components';
import { medias } from '../styles/medias';
import RatioPlaceholder from 'xureact/lib/module/components/layout/ratio-placeholder';

class Feature extends React.Component {
	static propTypes = {};
	
	render() {
		const props = this.props;
		return (
			<div className={props.className}>
				<div className="text">
					<h2 className="feature-title">{props.title}</h2>
					<div>{props.description}</div>
				</div>
				<div className="pic">
					<RatioPlaceholder width={810} height={490}>
						<div className="pic-img-wrapper">
							<img data-src={props.pic || "http://placehold.it/800x480"} alt="" className="lazyload"/>
						</div>
					</RatioPlaceholder>
				</div>
			</div>
		);
	}
	
	shouldComponentUpdate() {
		return false;
	}
}

//language=SCSS
Feature = Styled(Feature)`
& {
    position: relative;
    
    .feature-title {
    	margin-bottom: 1em;
    }
    
    padding: 0 20px;
    margin-top: 50px;
    @media screen and (${medias.mdMin}) {
    	&:not(:first-child) {
	        margin-top: 200px;
	    }
    }
    
    .vcenter-no-xs {
	    @media screen and (${medias.smMax}) {
	        transform: none !important;
	    }
    }
    
    > .text {
		position: relative;
    	padding: 0 5px;
	    display: flex;
	    flex-direction: column;
	    justify-content: space-evenly;
    }
    
	> .pic {
		position: relative;
		text-align: center;
		
		.ratio-placeholder {
			box-shadow: 2px 2px 8px rgba(0,0,0,0.10);
		}
		
		.pic-img-wrapper {
			background: #fff;
			padding: 5px;
		}
		
		img {
			max-width: 100%;
			background: #fff;
			//padding: 5px;
			width: 100%;
			
    		@media screen and (${medias.smMin}) {
				//height: 100%;
				//max-width: unset;
    		}
		}
	}
			
    @media screen and (${medias.smMin}) {
    	display: flex;
    	align-items: stretch;
    	align-content: stretch;
		> .text {
    		padding: 0 20px;
			width: 50%;
	    
		    @media screen and (${medias.xlMin}) {
		        padding: 75px 50px;
		    }
		}
		> .pic {
			width: 50%;
		}
		
		&:nth-child(2n) {
			flex-flow: row-reverse;
		}
	}
	
	&:after {
		content: "";
		clear: both;
		display: block;
	}
}
`;

export { Feature };
