import React from 'react'; //eslint-disable-line no-unused-vars

class WindowFrame extends React.Component {
    static propTypes = {};
    
    render() {
        const props = this.props;
        return (
            <div style={{ borderBottom: 'solid 2px #211504', borderRadius: 5, width: 600, maxWidth: '100%', padding: 0, boxShadow: '1px 1px 20px rgba(0,0,0,0.3)' }}>
                <img src="/img/window_title.svg" style={{width: '100%'}}/><br/>
                {props.children}
            </div>
        );
    }
    
    shouldComponentUpdate(nextProps) {
        return true;
    }
}

export { WindowFrame };
