import React from 'react';
import { MainLayout } from '../components/layout/main-layout';
import { CenteredView } from '../components/layout/centered-view';
import Styled from 'styled-components';
import { colors, rgba } from '../styles/colors';
import { Button } from '../components/buttons/button';
import { medias } from '../styles/medias';
import { Feature } from '../components/feature';
import { Helmet } from 'react-helmet';
import AboveFold from '../components/layout/above-fold';
import { graphql, StaticQuery } from 'gatsby';
import VCenter from 'xureact/lib/module/components/layout/vcenter';
import { FlexLayout, FlexChild } from 'xureact/lib/module/components/layout/flex-layout';
import UseCase from '../components/business/use-case';
import { WindowFrame } from '../components/static/window-frame';

class Out extends React.Component {
	static propTypes = {};
	
	render() {
		const props = this.props;
		return (
			<main className={props.className}>
				<Helmet>
					<title>HyperKeys</title>
					<link rel="canonical" href="https://hyperkeys.xureilab.com/" />
					<meta name="description" content="Map any action to any shortcut. Pin windows, toggle audio output, find your phone, and more !"/>
					<meta property="og:url" content="https://hyperkeys.xureilab.com/" />
					<meta property="og:description" content="Map any action to any shortcut. Pin windows, toggle audio output, find your phone, and more !" />
					<meta property="og:image" content="https://hyperkeys.xureilab.com/favicon.png" />
					<meta property="og:title" content="HyperKeys - Keyboard shortcuts on steroids" />
					<meta property="og:type" content="website" />
				</Helmet>
				<MainLayout>
					<StaticQuery query={graphql`
					    query {
					        file(relativePath: { eq: "squared_metal_@2X.png" }) {
					            childImageSharp {
					                gatsbyImageData(layout: CONSTRAINED)
					            }
					        }
					    }
					`} render={(data) => (
						<AboveFold image="/img/squared_metal.png" className="intro">
							<img src="/img/logo.svg" style={{
								height: 100,
								display: 'inline-block',
								verticalAlign: 'bottom',
								marginBottom: 20,
								borderRadius: 11,
								boxShadow: `0 0 10px 0px ${rgba(colors.primaryLighter, 0.6)}`,
							}}/>
							<h1 className="brand-heading">
								HyperKeys
							</h1>
							<p className="intro-text">
								Keyboard shortcuts on steroids
							</p>
							<a href="#about" className="btn btn-circle page-scroll">
								<i className="fa fa-angle-double-down animated"/>
							</a>
						</AboveFold>
					)}>
					</StaticQuery>
					
					<section id="about" className="text-center">
						<CenteredView width={1280}>
							<h2>About HyperKeys</h2>
							<p>Unleash the power of your keyboard by letting you bind ANY action to a shortcut.</p>
							<p>It's open-source and fully extendable. You can code you own actions or use one from the community !</p>
						</CenteredView>
					</section>
					
					<section id="how-it-works" className="text-center">
						<CenteredView width={1280}>
							<h2>How it works</h2>
							<FlexLayout wrap="wrap" direction="row-reverse">
								<FlexChild grow={1} width={300}>
									<div className="text-left" style={{ minHeight: 270 }}>
										<VCenter>
											<div className="text-left" style={{ padding: 15 }}>
												<p>
													HyperKeys allows you to map any keyboard shortcut to a specific action. <br/>
													Pre-defined actions are available, or you can create your own.
												</p>
												<ol className="text-left">
													<li>Choose an action in the config screen <i className="text-low-focus">e.g. Run command</i></li>
													<li>Set the shortcut you want to use <i className="text-low-focus">e.g. ALT+SHIFT+F</i></li>
													<li>Add extra config if needed <i className="text-low-focus">e.g. "firefox"</i></li>
												</ol>
											</div>
										</VCenter>
									</div>
								</FlexChild>
								<FlexChild grow={1} width={320} style={{maxWidth: 700}}>
									<div style={{ padding: 15 }}>
										<WindowFrame>
											<img src="/img/config.jpg" alt="Configuration screen" />
										</WindowFrame>
									</div>
								</FlexChild>
							</FlexLayout>
						</CenteredView>
					</section>
					
					<section id="features" className="text-center">
						<CenteredView width={1280}>
							<h2>How people are using HyperKeys</h2>
							
							<UseCase title="Navigate across windows" with="Pin Window" pic="far fa-window-restore">
								I have a lot of windows open all the time, and I must often navigate from one to another. <br/>
								I configured HyperKeys to switch to a particular window with a shortcut of my choosing. No more frustration or time lost with a wrong ALT-TAB.
							</UseCase>
							
							<UseCase title="Find My Phone" with="IFTTT Webhook" pic="fas fa-mobile-alt">
								I keep losing my phone. With HyperKeys, I made a shortcut to call IFTTT and call my Phone on Telegram.
							</UseCase>
							
							<UseCase title="Boost Productivity" with="Run Command" pic="fas fa-tachometer-alt">
								As a designer, I'm using multiple mini tools on a daily basis.
								I mapped each of them to a shortcut, and now I'm running them without even leaving the PDF I'm looking at.
								My work flow is much more fluid now!
							</UseCase>
							
							<UseCase title="Make your desk pet-friendly 🐱" with="Run Command" pic="fas fa-cat">
								My cat loves to sleep on my laptop keyboard when I'm working.
								Since I use an external one, I wrote a script to disable it with ALT+K on-demand.
								My beloved pet can now sleep well without any mistype.
							</UseCase>
							<div>
								<h3>... and more !</h3>
								<p>
									HyperKeys is Open-Source and Community-driven, which means you can create and share your own actions.<br/>
									The actions online store is coming soon.
								</p>
							</div>
						</CenteredView>
					</section>
					
					<section className="section-cta text-center">
						<h2>Unleash your keyboard now</h2>
						<br/>
						<a href="/download">
							<Button color="primary" className="main-cta">Download</Button>
						</a>
					</section>
				</MainLayout>
			</main>
		);
	}
	
	shouldComponentUpdate() {
		return true;
	}
}

//language=SCSS
Out = Styled(Out)`
& {
	top: 0;
	
	.main-cta {
		font-size: 1.3em;
		white-space: nowrap;
		padding: 0.8em 2em;
		border-radius: 6px;
		box-shadow: 0 0 8px rgba(0,0,0, 0.5) !important;
	}
	
	.section-cta {
		position: relative;
		
		&:before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
			top: 0;
			z-index: 0;
		}
		
		> * {
			position: relative;
			z-index: 1;
		}
		color: #fff;
		text-shadow: 0 0 3px #000, 0 0 6px #000;
		padding: 200px 0;
	    background-image: url(/bg_bees_black_sm.jpg);
	    @media screen and (${medias.mdMin}) {
	    	background-image: url(/bg_bees_black.jpg);
	    }
	    background-size: cover;
	    background-position: center;
	}
	
	#how-it-works ol > li {
		margin-bottom: 20px;
	}
}
`;

export default Out;
